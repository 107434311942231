



















import {Component} from 'vue-property-decorator';
import ArticleDetail from "@/views/Article/ArticleDetail.vue";

@Component
export default class ArticleView extends ArticleDetail {
  public loadData(): void {
    this.axios.get(this.settings.settings.host + '/api/articles/' + this.$route.params.id, { //this.id.toString(),{
      params: {}
    }).then(response => {
      this.article = response.data;
    });
  }

  getVideoCodeFromLink(link: string): string {
    return link
        .replace('https://www.youtube.com/watch?v=', '')
        .replace('&ab_channel=FundacjaProSpe', '')
  }
}
