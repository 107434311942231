


























import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";
import {Article} from "@/views/Article/Article";

@Component
export default class ArticleDetail extends Vue {
  public article = {
    id: 0
  };
  public settings: Settings;
  public id: number;

  public constructor() {
    super();
    this.settings = new Settings();
    this.id = +this.$route.params.id;
  }

  public getArticleType(type: number) {
    return Article.getTypeNameById(type);
  }

  public loadData(): void {
    this.axios.get(this.settings.settings.host + '/api/articles/' + this.$route.params.id, { //this.id.toString(),{
      params: {},
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.article = response.data;
    });
  }

  created(): void {
    this.loadData();
  }
}
